import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";

import index from "../components/index.vue";
// import mobileEditor from "../components/mobile/mobileEditor.vue";

const router = createRouter({
  routes: [{ path: "/", component: index }],
  history: createWebHashHistory(),
});
export default router;
