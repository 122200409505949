import "core-js/modules/es.array.push.js";
import { VueCropper } from "vue-cropper";
import "vue-cropper/dist/index.css";
import QRCode from "qrcode"; //引入生成二维码插件
import html2canvas from "html2canvas"; //引入生成二维码插件
import "../assets/fonts/font.css";
import gougouSvg from "./gougouSvg.vue";
import koalaSvg from "./koalaSvg.vue";
import watermark from "watermarkjs";
export default {
  components: {
    VueCropper,
    QRCode,
    gougouSvg,
    koalaSvg
  },
  props: ["Name"],
  data() {
    return {
      size: "meduim",
      compressUnit: "",
      showImg: false,
      showFeedback: false,
      feedBack: "",
      activeName: "画布",
      imgTool: "图片列表",
      currentImageSize: "1024_768",
      imageSizes: [{
        title: "iphone15 390*844",
        value: "390_844"
      }, {
        title: "微信头图 360*200",
        value: "360_200"
      }, {
        title: "图标 140*140",
        value: "140_140"
      }, {
        title: "PC大图 1960*480",
        value: "1960_480"
      }, {
        title: "PC大图 1024*768",
        value: "1024_768"
      }, {
        title: "简易logo",
        value: "290_70"
      }, {
        title: "图片尺寸",
        value: "source"
      }],
      fixedImags: [{
        src: require("../assets/keji/1.jpg"),
        src1: require("../assets/keji/2.jpg")
      }, {
        src: "https://itlifetime.com/resources/xiaochengxu/1711527748221.jpg",
        src1: "https://itlifetime.com/resources/xiaochengxu/1711527758582.jpg"
      }, {
        src: "https://itlifetime.com/resources/xiaochengxu/1711527574458.webp",
        src1: "https://itlifetime.com/resources/xiaochengxu/1711527560487.webp"
      }, {
        src: "https://itlifetime.com/resources/xiaochengxu/1711527718530.webp",
        src1: "https://itlifetime.com/resources/xiaochengxu/1711527834758.jpeg"
      }, {
        src: "https://itlifetime.com/resources/xiaochengxu/1711528806847.jpg",
        src1: "https://itlifetime.com/resources/xiaochengxu/1711527655764.jpg"
      }, {
        src: require("../assets/gou/10.jpg"),
        src1: "https://itlifetime.com/resources/xiaochengxu/1711527693929.jpg"
      }],
      currentEditImg: "",
      cropImgWidth: "",
      cropImgHeight: "",
      watermarkImg: "",
      watermarkStr: "我是水印",
      isKoutu: false,
      mainHeight: "",
      imgMainHeight: "",
      imageHeight: "",
      compressImageHeight: "",
      editHeight: "",
      name: this.Name,
      previews: {},
      imgName: "newImg",
      QRlink: "",
      QRImgUrl: "",
      isDrag: false,
      isQrCode: false,
      option: {
        img: "",
        //裁剪图片的地址
        outputSize: 1,
        //裁剪生成图片的质量(可选0.1 - 1)
        outputType: "png",
        //裁剪生成图片的格式（jpeg || png || webp）
        info: true,
        //图片大小信息
        canScale: true,
        //图片是否允许滚轮缩放
        autoCrop: true,
        //是否默认生成截图框
        autoCropWidth: 230,
        //默认生成截图框宽度
        autoCropHeight: 150,
        //默认生成截图框高度
        fixed: true,
        //是否开启截图框宽高固定比例
        fixedNumber: [1.53, 1],
        //截图框的宽高比例
        full: false,
        //false按原比例裁切图片，不失真
        fixedBox: false,
        //固定截图框大小，不允许改变
        canMove: false,
        //上传图片是否可以移动
        canMoveBox: true,
        //截图框能否拖动
        original: false,
        //上传图片按照原始比例渲染
        centerBox: true,
        //截图框是否被限制在图片里面
        height: true,
        //是否按照设备的dpr 输出等比例图片
        infoTrue: false,
        //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000,
        //限制图片最大宽度和高度
        enlarge: 1 //图片根据截图框输出比例倍数
        //mode: '230px 150px', //图片默认渲染方式
      },

      compressImg: "",
      fileList: [],
      type: "图片生成",
      isCompress: false,
      compressSuccessImgList: [],
      buzhi: "",
      logoImg: require("../assets/logo.png"),
      currentTtl: "",
      logoImgs: "logo-imgs",
      //添加遮罩层
      myLogo: {
        inputContent: "",
        color1: "",
        fontSize: 30,
        isBg: "无",
        isIcon: "有",
        isCircle: "否",
        isItalic: "否",
        iconSize: 30,
        textAlign: "否",
        canvasDefaultWidth: 290,
        canvasDefaultHeight: 40,
        texts: [],
        imgs: [],
        isBglist: false,
        //默认不渐变
        bgListDir: "to top",
        //默认渐变方向
        bglist: [],
        //背景色渐变
        canvasImgDefaultWidth: 1024,
        canvasImgDefaultHeight: 0
      },
      currentText: {},
      currentTextIndex: "",
      currentImg: {},
      currentSourceImg: {},
      currentTtlIndex: 0,
      fixedFontColor: ["", "#fff", "#3e13eb", "#000000", "rgb(28, 66, 160)", "#A210F7"],
      fixedBgColor: ["", "#fff", "#3e13eb", "#000000", "#1D7D05", "orange", "rgb(28, 66, 160)", "#A210F7", "rgb(251, 194, 235)", "rgb(161, 140, 209)"],
      ttlList: [{
        name: "宋体",
        fontFamily: "宋体",
        color: "#107BEE"
      }, {
        name: "微软雅黑",
        fontFamily: "微软雅黑",
        color: "#107BEE"
      }, {
        name: "隶书",
        fontFamily: "隶书",
        color: "#107BEE"
      }, {
        name: "方正姚体",
        fontFamily: "方正姚体",
        color: "#107BEE"
      }, {
        name: "华文琥珀",
        fontFamily: "华文琥珀",
        color: "#107BEE"
      }, {
        name: "华文彩云",
        fontFamily: "华文彩云",
        color: "#107BEE"
      }, {
        name: "楷体",
        fontFamily: "楷体",
        color: "#107BEE"
      }, {
        name: "幼圆",
        fontFamily: "幼圆",
        color: "#107BEE"
      }, {
        name: "标准",
        fontFamily: "led",
        color: "#107BEE"
      }, {
        name: "简约",
        fontFamily: "led1",
        color: "#107BEE"
      }, {
        name: "纽约",
        fontFamily: "led2",
        color: "#107BEE"
      }, {
        name: "少女",
        fontFamily: "led3",
        color: "#107BEE"
      }, {
        name: "铠甲体",
        fontFamily: "led4",
        color: "#107BEE"
      }, {
        name: "光",
        fontFamily: "led5",
        color: "#107BEE"
      }, {
        name: "斜体",
        fontFamily: "led6",
        color: "#107BEE"
      }, {
        name: "星",
        fontFamily: "led7",
        color: "#107BEE"
      }, {
        name: "懒人",
        fontFamily: "led8",
        color: "#107BEE"
      }, {
        name: "开到金光",
        fontFamily: "led9",
        color: "#107BEE"
      }, {
        name: "欢乐",
        fontFamily: "led10",
        color: "#107BEE"
      }, {
        name: "走",
        fontFamily: "led11",
        color: "#107BEE"
      }, {
        name: "穷人",
        fontFamily: "led12",
        color: "#107BEE"
      }, {
        name: "四叶草",
        fontFamily: "led13",
        color: "#107BEE"
      }, {
        name: "魔法",
        fontFamily: "led14",
        color: "#107BEE"
      }, {
        name: "涂鸦体",
        fontFamily: "led15",
        color: "#107BEE"
      }, {
        name: "战斗",
        fontFamily: "led16",
        color: "#107BEE"
      }, {
        name: "阴影",
        fontFamily: "led17",
        color: "#107BEE"
      }, {
        name: "我反对",
        fontFamily: "led18",
        color: "#107BEE"
      }, {
        name: "大胜",
        fontFamily: "led19",
        color: "#107BEE"
      }],
      formMaxHeight: "",
      myCompress: {
        compressType: "压缩图片",
        width: "",
        height: ""
      },
      isNew: false,
      imgBase64: "",
      isDragEnd: false,
      theme: {
        bg: "#bff9d7",
        color: "black"
      },
      imgColor: "",
      fillColor: "",
      isFill: false,
      isQuse: false
    };
  },
  created() {
    this.getInit();
  },
  watch: {
    "currentImg.filterBlur"() {
      this.setNewImage();
    },
    "currentImg.grayscale"() {
      this.setNewImage();
    }
  },
  mounted() {
    // const canvas = this.$refs.canvas;
    // const ctx = canvas.getContext("2d");
    // const img = new Image();
    // img.src = "http://example.com/image.png";
    // img.onload = function () {
    //   canvas.width = img.width;
    //   canvas.height = img.height;
    //   ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    // };
  },
  methods: {
    getInit() {
      this.mainHeight = window.innerHeight + "px";
      this.imgMainHeight = window.innerHeight - 63 + "px";
      this.formMaxHeight = window.innerHeight - 160 + "px";
      this.editHeight = window.innerHeight - 120 + "px";
      this.imageHeight = window.innerHeight - 80 + "px";
      this.compressImageHeight = window.innerHeight - 200 + "px";
      if (window.sessionStorage.getItem("handleImgType")) {
        this.type = window.sessionStorage.getItem("handleImgType");
      }
      //计算canvas画框高度
      this.myLogo.canvasImgDefaultHeight = window.innerHeight - 90;
    },
    //清理灰度，灰度会改变图片
    clearFilterbulur() {
      this.currentImg.filterBlur = this.currentSourceImg.filterBlur;
      this.currentImg.src = this.currentSourceImg.src;
    },
    //撤销所有操作，包括还原图片
    cancelHandleImg() {
      this.currentImg.src = this.currentSourceImg.src;
      this.currentImg.top = this.currentSourceImg.top;
      this.currentImg.left = this.currentSourceImg.left;
      this.currentImg.id = this.currentSourceImg.id;
      this.currentImg.bili = this.currentSourceImg.bili;
      this.currentImg.grayscale = this.currentSourceImg.grayscale;
      this.currentImg.opacity = this.currentSourceImg.opacity;
      this.currentImg.filterBlur = this.currentSourceImg.filterBlur;
      this.currentImg.transformRotate = this.currentSourceImg.transformRotate;
      this.currentImg.isMask = this.currentSourceImg.isMask;
      this.currentImg.transform3d = this.currentSourceImg.transform3d;
      this.currentImg.perspective3d = this.currentSourceImg.perspective3d;
      this.currentImg.deg3d = this.currentSourceImg.deg3d;
      this.currentImg.x3d = this.currentSourceImg.x3d;
      this.currentImg.y3d = this.currentSourceImg.y3d;
      this.currentImg.z3d = this.currentSourceImg.z3d;
    },
    getBgColor(e) {
      var x = e.layerX;
      var y = e.layerY;
      // 加载并显示图像到canvas上
      var img = new Image();
      img.src = this.currentImg.src; // 这里需要将'path/to/your/image.jpg'替换为实际图像的路径
      let _this = this;
      let classId = "quse" + _this.currentImg.id;
      img.onload = function () {
        // 创建一个新的canvas元素
        var canvas = document.querySelector("#" + classId);
        if (!canvas) {
          canvas = document.createElement("canvas");
          canvas.setAttribute("id", classId); //添加样式属性

          document.body.appendChild(canvas);
        }

        // 设置canvas大小与要处理的图像相同
        canvas.width = img.width; // 这里需要将imageWidth替换为实际图像的宽度
        canvas.height = img.height; // 这里需要将imageHeight替换为实际图像的高度

        // 获取2D上下文对象
        var context = canvas.getContext("2d");
        context.drawImage(img, 0, 0); // 将图像绘制到canvas上

        // 从canvas上读取指定位置的像素数据
        var pixelData = context.getImageData(x, y, 1, 1).data;

        // 提取RGB分量的值
        var redValue = pixelData[0];
        var greenValue = pixelData[1];
        var blueValue = pixelData[2];
        console.log("红色分量：" + redValue);
        console.log("绿色分量：" + greenValue);
        console.log("蓝色分量：" + blueValue);
        _this.imgColor = "rgb(" + redValue + "," + greenValue + "," + blueValue + ")";
        //alert(_this.imgColor);
        //_this.myLogo.bg = _this.imgColor;
        // alert(canvas.toDataURL());
        canvas.setAttribute("style", "display:none"); //添加样式属性
      };
    },

    fillImg(e) {
      var x = e.layerX;
      var y = e.layerY;
      // 加载并显示图像到canvas上
      var img = new Image();
      img.src = this.currentImg.src; // 这里需要将'path/to/your/image.jpg'替换为实际图像的路径
      let _this = this;
      let classId = "quse" + _this.currentImg.id;
      // 创建一个新的canvas元素
      var canvas = document.querySelector("#" + classId);
      if (!canvas) {
        canvas = document.createElement("canvas");
        canvas.setAttribute("id", classId); //添加样式属性
        canvas.width = img.width; // 这里需要将imageWidth替换为实际图像的宽度
        canvas.height = img.height; // 这里需要将imageHeight替换为实际图像的高度
        document.body.appendChild(canvas);
        canvas.setAttribute("style", "display:none"); //添加样式属性
      }

      let newSrc = "";
      img.onload = function () {
        // // 获取2D上下文对象
        var ctx = canvas.getContext("2d");
        //设置canvas大小与要处理的图像相同
        ctx.drawImage(img, 0, 0); // 将图像绘制到canvas上

        // //思路： 从canvas上读取指定位置的像素数据，然后循环遍历将该像素附近+10 -10范围内全部替换成透明色
        // var fixedImageData = ctx.getImageData(x, y, 1, 1);
        // var allImgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        // console.log(
        //   fixedImageData.data[0] +
        //     fixedImageData.data[1] +
        //     fixedImageData.data[2] +
        //     fixedImageData.data[3]
        // );
        // for (let i = 0; i < allImgData.data.length; i += 4) {
        //   if (
        //     (allImgData.data[i] == fixedImageData.data[0] &&
        //       allImgData.data[i + 1] == fixedImageData.data[1] &&
        //       allImgData.data[i + 2] == fixedImageData.data[2]) ||
        //     (allImgData.data[i] == 255 &&
        //       allImgData.data[i + 1] == 255 &&
        //       allImgData.data[i + 2] == 255) ||
        //     (allImgData.data[i] == 248 &&
        //       allImgData.data[i + 1] == 248 &&
        //       allImgData.data[i + 2] == 248) ||
        //     (allImgData.data[i] == 247 &&
        //       allImgData.data[i + 1] == 247 &&
        //       allImgData.data[i + 2] == 247) ||
        //     (allImgData.data[i] == 246 &&
        //       allImgData.data[i + 1] == 246 &&
        //       allImgData.data[i + 2] == 246) ||
        //     (allImgData.data[i] == 246 &&
        //       allImgData.data[i + 1] == 247 &&
        //       allImgData.data[i + 2] == 248) ||
        //     (allImgData.data[i] == 250 &&
        //       allImgData.data[i + 1] == 251 &&
        //       allImgData.data[i + 2] == 252) ||
        //     (allImgData.data[i] == 251 &&
        //       allImgData.data[i + 1] == 251 &&
        //       allImgData.data[i + 2] == 251)
        //   ) {
        //     allImgData.data[i] = "255";
        //     allImgData.data[i + 1] = "2550";
        //     allImgData.data[i + 2] = "255";
        //     allImgData.data[i + 3] = "0";
        //   }
        // }
        // 将新像素数据放回Canvas
        // ctx.putImageData(allImgData, 0, 0);

        // 指定要获取颜色的位置（x、y）
        // 先将背景色填充为透明，以便后面可以看到背景图片
        ctx.globalCompositeOperation = "destination-out";
        // 设置背景擦除区域的大小
        ctx.fillRect(x, y, 1, 1);
        // 重置合成操作为默认值
        ctx.globalCompositeOperation = "source-over";
        // 设置新的背景色
        ctx.fillStyle = _this.fillColor; // 替换为你想要的颜色
        ctx.beginPath();
        ctx.arc(x, y, 10, 0, 2 * Math.PI); // 创建一个圆形的背景色填充区域
        ctx.fill();
        newSrc = canvas.toDataURL();
        _this.currentImg.src = newSrc;
      };
    },
    async saveFeedback() {
      if (!this.feedBack) {
        this.errorMsgBox("还未输入内容哦！");
        return false;
      }
      //编辑
      let data = {};
      let params = {};
      let feedBack = {
        content: this.feedBack,
        openid: "huatu"
      };
      params.ephemeralParam = feedBack;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/saveFeedback"));
      if (res.code >= 0) {
        this.successMsgBox("提交成功，感谢您的建议！");
        this.showFeedback = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    gotoText(text, index) {
      // const obj2 = Object.assign({}, text);
      this.activeName = "文字";
      this.currentText = text;
      this.currentTextIndex = index;
    },
    gotoImgTool(img) {
      this.currentImg = img;
      this.imgTool = "工具";
    },
    gotoImg(e, text) {
      // const obj2 = Object.assign({}, text);
      this.currentImg = text;
      this.activeName = "图片";
      if (this.isQuse) {
        this.getBgColor(e);
      }
      if (this.isFill) {
        this.fillImg(e);
      }
    },
    yulan() {
      this.isNew = !this.isNew;
      console.log(this.currentImg.src);
    },
    getCurrentImg(f) {
      this.currentEditImg = f;
    },
    //当按钮切换时，清除所有图片
    changeImgHandle(h) {
      this.currentEditImg = "";
      this.imgBase64 = "";
      this.fileList = [];
      if (this.$refs.compressImgUpload) {
        this.$refs.compressImgUpload.clearFiles();
      }
      if (this.$refs.editImgWidthHeightUplod) {
        this.$refs.editImgWidthHeightUplod.clearFiles();
      }
    },
    clearWaterImg() {
      this.$refs.waterUpload.clearFiles();
      const div = document.getElementById("123");
      document.body.removeChild(div);
      this.watermarkImg = "";
    },
    downloadWatheImg() {
      let _canvas = document.getElementById("watermark-img");
      // 进行操作
      var canvas2 = document.createElement("canvas");
      var w = parseInt(window.getComputedStyle(_canvas).width);
      var h = parseInt(window.getComputedStyle(_canvas).height);
      //将canvas画布放大若干倍，然后盛放在较小的容器内，以破解模糊
      canvas2.width = w * 1;
      canvas2.height = h * 1;
      canvas2.style.width = w + "px";
      canvas2.style.height = h + "px";
      var context = canvas2.getContext("2d");
      let _this = this;
      html2canvas(document.querySelector("#watermark-img"), {
        canvas: canvas2,
        backgroundColor: null
      }).then(function (canvas) {
        const downloadElement = document.createElement("a");
        downloadElement.href = canvas.toDataURL();
        downloadElement.download = "1222.png";
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
      });
    },
    getWatermarkImg(f) {
      this.watermarkImg = f;
      // img.src = f.url;
      let _this = this;
      // img.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = 150;
      canvas.height = 120;

      // draw the original image on canvas
      // context.drawImage(img, 0, 0);

      // draw the name or datetime on canvas
      const text = _this.watermarkStr || new Date().toLocaleString();
      context.font = "18px Vedana";
      context.rotate(-15 * Math.PI / 180);
      context.textAlign = "left";
      context.fillStyle = "rgba(200,200,200,0.40)";
      context.fillText(text, canvas.width / 8, canvas.height / 2);
      const div = document.createElement("div");
      // const div = document.getElementById("watermark-img");

      div.id = "123";
      div.style.pointerEvents = "none";
      div.style.top = "130px";
      div.style.left = "110px";
      div.style.position = "fixed";
      div.style.zIndex = "100000";
      div.style.width = "1024px"; // document.documentElement.clientWidth +
      div.style.height = "768px"; //document.documentElement.clientHeight +
      div.style.background = "url(" + canvas.toDataURL() + ") left top repeat";
      document.body.appendChild(div);

      //_this.watermarkImg = canvas.toDataURL();
      // };
    },

    //加入新text
    newText() {
      // const text = Object.assign({}, this.currentText);
      let text = {};
      text.id = this.random(20);
      text.top = 0;
      text.left = 0;
      text.inputContent = this.currentText.inputContent ? this.currentText.inputContent : "画图呀";
      text.fontSize = 25;
      text.fontFamily = "宋体";
      text.textTopPadding = 0;
      text.textLeftPadding = 0;
      text.textRightPadding = 0;
      text.textBottomPadding = 0;
      text.lockDrag = false;
      text.isFlex = "横排";
      this.myLogo.texts.push(text);
      // this.currentText.fontSize = text.fontSize;
      // this.currentText.inputContent = text.inputContent;
      // this.currentText.id = text.id;
      this.currentTextIndex = this.myLogo.texts.length - 1;
      this.currentText = text;
      this.successMsgBox("添加成功");
    },
    random(length) {
      return Math.round(Math.random() * length);
    },
    changeFont() {
      this.currentTtl = this.ttlList[this.currentTtlIndex];
      this.myLogo.fontFamily = this.currentTtl.fontFamily;
      this.currentText.fontFamily = this.myLogo.fontFamily;
    },
    // 拖拽开始事件
    dragstart(e, text) {
      if (this.isDrag) {
        text.startclientX = e.clientX; // 记录拖拽元素初始位置
        text.startclientY = e.clientY;
      }
    },
    // 拖拽完成事件
    dragend(e, text) {
      if (!text.lockDrag && this.isDrag) {
        let x = e.clientX - text.startclientX; // 计算偏移量
        let y = e.clientY - text.startclientY;
        text.left += x; // 实现拖拽元素随偏移量移动
        text.top += y;
        console.log(x);
        console.log(y);
        if (text.left) {}
      }
    },
    changeTextPosition() {
      if (this.currentText.textPosition == "左上角") {
        this.currentText.top = 0;
        this.currentText.left = 0;
      } else if (this.currentText.textPosition == "右上角") {
        document.getElementsByClassName("my-huabu")[this.currentTextIndex].style.top = 0;
        document.getElementsByClassName("my-huabu")[this.currentTextIndex].style.right = 0;
        document.getElementsByClassName("my-huabu")[this.currentTextIndex].style.bottom = null;
        document.getElementsByClassName("my-huabu")[this.currentTextIndex].style.left = null;
      } else if (this.currentText.textPosition == "右下角") {
        document.getElementsByClassName("my-huabu")[this.currentTextIndex].style.bottom = 0;
        document.getElementsByClassName("my-huabu")[this.currentTextIndex].style.right = 0;
        document.getElementsByClassName("my-huabu")[this.currentTextIndex].style.top = null;
        document.getElementsByClassName("my-huabu")[this.currentTextIndex].style.left = null;
      } else if (this.currentText.textPosition == "左下角") {
        document.getElementsByClassName("my-huabu")[this.currentTextIndex].style.bottom = 0;
        document.getElementsByClassName("my-huabu")[this.currentTextIndex].style.left = 0;
        this.currentText.left = 0;
        document.getElementsByClassName("my-huabu")[this.currentTextIndex].style.top = null;
        document.getElementsByClassName("my-huabu")[this.currentTextIndex].style.right = null;
      } else if (this.currentText.textPosition == "居中") {
        // document.getElementsByClassName("my-huabu")[
        //   this.currentTextIndex
        // ].style.top = "50%";
        // document.getElementsByClassName("my-huabu")[
        //   this.currentTextIndex
        // ].style.left = "50%";
        // document.getElementsByClassName("my-huabu")[
        //   this.currentTextIndex
        // ].style.transform = "translate(-50%,-50%)";

        //文字输入时候结合文字大小来综合计算
        //高度与字体大小成一定比例

        this.currentText.top = this.myLogo.canvasImgDefaultHeight / 2 - (this.currentText.fontSize + 10) / 2 - this.currentText.textTopPadding / 2 + this.currentText.textBottomPadding / 2;

        //宽度的平均值为字体宽度减去左padding的一半加上右panding的一半
        this.currentText.left = this.myLogo.canvasImgDefaultWidth / 2 - this.currentText.fontSize * this.currentText.inputContent.length / 2 - this.currentText.textLeftPadding / 2 + this.currentText.textRightPadding / 2;
        document.getElementsByClassName("my-huabu")[this.currentTextIndex].style.bottom = null;
        document.getElementsByClassName("my-huabu")[this.currentTextIndex].style.right = null;
      }
    },
    changeImageSize(val) {
      if (val !== "source") {
        this.myLogo.canvasImgDefaultWidth = val.split("_")[0];
        this.myLogo.canvasImgDefaultHeight = val.split("_")[1];
      } else {
        //图片尺寸
        if (this.myLogo.imgs && this.myLogo.imgs.length > 0) {
          this.myLogo.canvasImgDefaultWidth = this.myLogo.imgs[0].width;
          this.myLogo.canvasImgDefaultHeight = this.myLogo.imgs[0].height;
        }
      }
    },
    doResizeImg(val) {
      this.currentImg.height = this.currentImg.sourceHeight * val / 100;
      this.currentImg.width = this.currentImg.sourceWidth * val / 100;

      // if (
      //   img.height > this.myLogo.canvasImgDefaultHeight ||
      //   img.width > this.myLogo.canvasImgDefaultWidth
      // ) {
      //   //缩小60%，太大了 多缩小
      //   if (
      //     img.height % this.myLogo.canvasImgDefaultHeight >= 2 ||
      //     img.width % this.myLogo.canvasImgDefaultWidth >= 2
      //   ) {
      //     img.height = img.height * 0.2;
      //     img.width = img.width * 0.2;
      //   } else {
      //     img.height = img.height * 0.6;
      //     img.width = img.width * 0.6;
      //   }
      // }
    },

    selectFixedImg(url) {
      const image = new Image();
      let _this = this;
      image.src = url;
      let img = {
        src: url,
        top: 0,
        left: 0,
        id: this.random(20),
        bili: 1,
        isMask: false,
        transform3d: false,
        perspective3d: 600,
        deg3d: 46,
        x3d: 1,
        y3d: 1,
        z3d: 1,
        grayscale: "",
        opacity: "",
        filterBlur: ""
      };
      this.currentSourceImg = {
        src: url,
        top: 0,
        left: 0,
        id: this.random(20),
        bili: 1,
        isMask: false,
        transform3d: false,
        perspective3d: 600,
        deg3d: 46,
        x3d: 1,
        y3d: 1,
        z3d: 1,
        grayscale: "",
        opacity: "",
        filterBlur: "",
        transformRotate: ""
      };
      image.onload = () => {
        //不能超过画布尺寸
        img.width = image.width;
        img.height = image.height;
        img.sourceWidth = image.width;
        img.sourceHeight = image.height;
        // _this.doResizeImg(img);

        _this.activeName = "图片";
        _this.currentImg = img;
        _this.myLogo.imgs.push(_this.currentImg);
      };
      this.successMsgBox("添加图片成功！");
    },
    selectBgImg(e) {
      let file = e.target.files[0];
      if (!/.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: "图片类型要求：jpeg、jpg、png",
          type: "error"
        });
        return false;
      }
      //转化为blob
      let reader = new FileReader();
      reader.onload = e => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.currentImg = {
          src: data,
          top: 0,
          left: 0,
          id: this.random(20),
          bili: 1,
          isMask: false,
          transform3d: false,
          perspective3d: 600,
          deg3d: 46,
          x3d: 1,
          y3d: 1,
          z3d: 1,
          grayscale: "",
          opacity: "",
          filterBlur: "",
          transformRotate: ""
        };
        this.currentSourceImg = {
          src: data,
          top: 0,
          left: 0,
          id: this.random(20),
          bili: 1,
          isMask: false,
          transform3d: false,
          perspective3d: 600,
          deg3d: 46,
          x3d: 1,
          y3d: 1,
          z3d: 1,
          grayscale: "",
          opacity: "",
          filterBlur: ""
        };
        const image = new Image();
        let _this = this;
        image.src = data;
        image.onload = () => {
          //不能超过画布尺寸
          //不要修改图片原始尺寸
          _this.currentImg.width = image.width;
          _this.currentImg.height = image.height;
          _this.currentImg.sourceWidth = image.width;
          _this.currentImg.sourceHeight = image.height;

          //  _this.doResizeImg(img);

          // if (img.height > _this.myLogo.canvasImgDefaultHeight) {
          //   img.perHeight = "60%";
          // }
          _this.myLogo.imgs.push(_this.currentImg);
        };
      };

      //转化为base64
      reader.readAsDataURL(file);
    },
    removeImg(index) {
      this.myLogo.imgs.splice(index, 1);
    },
    removeAllImg() {
      this.myLogo.imgs = [];
    },
    removeBglistIndex(index) {
      this.myLogo.bglist.splice(index, 1);
      this.setNewBg();
    },
    setNewBg() {
      let str = "";
      this.myLogo.bglist.forEach((element, index) => {
        if (index != this.myLogo.bglist.length - 1) {
          str += element + ",";
        } else {
          str += element;
        }
      });
      this.myLogo.bg = "linear-gradient(" + this.myLogo.bgListDir + "," + str + ")";
    },
    addDefindColor(v) {
      //新增到bglist
      if (this.myLogo.isBglist) {
        this.myLogo.bglist.push(v);
        this.setNewBg();
      }
    },
    //重新生成下
    setBg(i) {
      if (this.myLogo.isBglist) {
        this.myLogo.bglist.push(i);
        let str = "";
        this.myLogo.bglist.forEach((element, index) => {
          if (index != this.myLogo.bglist.length - 1) {
            str += element + ",";
          } else {
            str += element;
          }
        });
        this.myLogo.bg = "linear-gradient(" + this.myLogo.bgListDir + "," + str + ")";
      } else {
        this.myLogo.bg = i;
      }
    },
    changeIsBglist() {
      if (!this.myLogo.isBglist) {
        this.myLogo.bglist = [];
        this.myLogo.bg = "";
      }
    },
    setNewImage() {
      //查看是否存在滤镜，如果存在滤镜，则重新生成滤镜图片
      this.myLogo.imgs.forEach(element => {
        if (element.grayscale || element.filterBlur) {
          let img = new Image();
          img.src = element.src;
          img.onload = () => {
            let width = img.width;
            let height = img.height;
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = width;
            canvas.height = height;
            if (element.filterBlur) {
              ctx.filter = "blur(" + element.filterBlur + "px)";
            } else {
              ctx.filter = "grayscale(" + element.grayscale + "%)";
            }
            ctx.drawImage(img, 0, 0, width, height);
            element.src = canvas.toDataURL();
          };
        }
      });
    },
    async newImage() {
      // const c = await html2canvas(this.$refs.content);
      // const image = c.toDataURL();
      // this.buzhi = image;
      // console.log(image);
      //创建一个新的canvas
      this.isNew = true;
      this.$nextTick(() => {
        let _canvas = document.getElementById("content");
        // 进行操作
        var canvas2 = document.createElement("canvas");
        var w = parseInt(window.getComputedStyle(_canvas).width);
        var h = parseInt(window.getComputedStyle(_canvas).height);
        //将canvas画布放大若干倍，然后盛放在较小的容器内，以破解模糊
        canvas2.width = w * 2;
        canvas2.height = h * 2;
        canvas2.style.width = w * 2 + "px";
        canvas2.style.height = h * 2 + "px";
        let ctxt = canvas2.getContext("2d");
        let _this = this;
        html2canvas(document.querySelector("#content"), {
          canvas: canvas2,
          dpi: 500,
          scale: 2,
          backgroundColor: null
        }).then(function (canvas) {
          _this.isNew = false;
          const image = canvas.toDataURL();
          // 获取生成的图片的url,replace("image/png", "image/octet-stream") 作用是告诉浏览器不要打开直接下载
          var imgUri = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
          _this.successMsgBox("下载成功！");
          _this.buzhi = image;
          _this.downloadNewImage();
        });
      });
    },
    //删除元素
    deleteElement() {
      if (this.currentText && Object.keys(this.currentText).length > 0) {
        this.myLogo.texts.some((element, index) => {
          if (element.id == this.currentText.id) {
            this.myLogo.texts.splice(index, 1);
            return true;
          }
        });
        this.currentText = {};
      }
      if (this.currentImg) {
        this.myLogo.imgs.some((element, index) => {
          if (element.id == this.currentImg.id) {
            this.myLogo.imgs.splice(index, 1);
            return true;
          }
        });
        this.currentImg = {};
      }
    },
    onDragStart(event) {
      this.isDragEnd = true;
      const progressBox = this.$el.querySelector(".progress");
      progressBox.addEventListener("mouseup", this.onDragEnd);
      progressBox.addEventListener("mousemove", this.onDrag);
    },
    onDragEnd() {
      this.isDragEnd = false;
    },
    onDrag(event) {
      if (this.isDragEnd) {
        const progressBox = this.$el.querySelector(".progress");
        const rect = progressBox.getBoundingClientRect();
        const width = rect.width;
        const offsetX = event.clientX - rect.left;
        let percentage = Math.round(offsetX / width * 100);
        percentage = Math.max(0, Math.min(percentage, 100));
        this.myLogo.fontSize = percentage;
        this.currentText.fontSize = percentage;
      }
    },
    downloadNewImage() {
      const downloadElement = document.createElement("a");
      downloadElement.href = this.buzhi;
      downloadElement.download = "222.png";
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
    },
    topIndex(index) {
      let i = this.myLogo.imgs[index];
      let i1 = this.myLogo.imgs[index - 1];
      this.myLogo.imgs[index] = i1;
      this.myLogo.imgs[index - 1] = i;
    },
    bottomIndex(index) {
      let i = this.myLogo.imgs[index];
      let i1 = this.myLogo.imgs[index + 1];
      this.myLogo.imgs[index] = i1;
      this.myLogo.imgs[index + 1] = i;
    },
    // changeType(type) {
    //   window.sessionStorage.setItem("handleImgType", type);
    //   this.type = type;
    // },
    handleClick(tab) {
      // this.type = tab.paneName;
      this.type = tab;
      window.sessionStorage.setItem("handleImgType", this.type);
    },
    newImageTabClick(tab) {},
    //图片在线压缩，压缩到200K以内，也可以拉伸扩大
    compressImage() {
      const maxResolution = 1000;
      this.isCompress = true;
      let _this = this;
      this.fileList.forEach((element, index) => {
        let compressImg = {};
        //获取kb信息
        const reader = new FileReader();
        reader.readAsDataURL(element.raw);
        reader.onload = e => {
          const image = new Image();
          image.src = e.target.result;
          image.onload = () => {
            let width = image.width;
            let height = image.height;
            compressImg.name = element.name;
            compressImg.sourceImgWidth = width;
            compressImg.sourceImgHeight = height;

            //压缩或修改分辨率
            if (_this.myCompress.compressType == "修改尺寸") {
              width = _this.myCompress.width;
              height = _this.myCompress.height;
            } else {
              if (width > height) {
                if (width > maxResolution) {
                  height *= maxResolution / width;
                  width = maxResolution;
                }
              } else {
                if (height > maxResolution) {
                  width *= maxResolution / height;
                  height = maxResolution;
                }
              }
            }
            if (!width || !height) {
              _this.errorMsgBox("请输入要修改的宽高的比率");
              return;
            } else {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              canvas.width = width;
              canvas.height = height;
              ctx.drawImage(image, 0, 0, width, height);
              compressImg.src = canvas.toDataURL("image/jpeg", 0.8);
              console.log(compressImg.src);

              //完成之后下载图片，不下载放新的地方
              const newImage = new Image();
              newImage.src = compressImg.src;
              newImage.onload = () => {
                let width = newImage.width;
                let height = newImage.height;
                compressImg.newImgHeight = height;
                compressImg.newImgWidth = width;
                _this.compressSuccessImgList[index] = compressImg;
              };
            }
          };
        };
      });
      this.successMsgBox("处理成功！");
      this.isCompress = false;
    },
    clearCompressImage() {
      this.fileList = [];
      this.compressSuccessImgList = [];
    },
    pause(msec) {
      // 异步暂停函数
      return new Promise((resolve, reject) => {
        setTimeout(resolve, msec || 1000);
      });
    },
    async downloadCompressSucessImg() {
      const downloadElement = document.createElement("a");
      document.body.appendChild(downloadElement);
      let count = 0; // 已下载的文件数
      // this.compressSuccessImgList.forEach(element => {
      //   downloadElement.href = element.src;
      //   downloadElement.download = element.name;
      //   downloadElement.click();
      //   if (++count >= 10) {
      //    await this.pause(1000);
      //     count = 0;
      //   }
      // });
      for (let element of this.compressSuccessImgList) {
        downloadElement.href = element.src;
        downloadElement.download = element.name;
        downloadElement.click();
        if (++count >= 10) {
          await this.pause(1000);
          count = 0;
        }
      }
      document.body.removeChild(downloadElement);
    },
    getQRcode() {
      let opts = {
        errorCorrectionLevel: "L",
        //容错级别
        type: "image/png",
        //生成的二维码类型
        quality: 0.3,
        //二维码质量
        margin: 4,
        //二维码留白边距
        width: 256,
        //宽
        height: 256,
        //高
        text: "",
        //二维码内容
        color: {
          dark: "#666666",
          //前景色

          light: "#fff" //背景色
        }
      };

      QRCode.toDataURL(this.QRlink, opts, (err, url) => {
        if (err) throw err;
        //将生成的二维码路径复制给data的QRImgUrl
        this.QRImgUrl = url;
      });
    },
    //初始化函数
    imgLoad(msg) {
      console.log("工具初始化函数=====" + msg);
    },
    //图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    //向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    //向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    //实时预览函数
    realTime(data) {
      console.log(this.previews);
      this.previews = data;
    },
    //选择图片
    selectImg(e) {
      let file = e.target.files[0];
      if (!/.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: "图片类型要求：jpeg、jpg、png",
          type: "error"
        });
        return false;
      }
      //转化为blob
      let _this = this;
      let reader = new FileReader();
      reader.onload = e => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.option.img = data;
        const image = new Image();
        image.src = this.option.img;
        image.onload = () => {
          _this.cropImgWidth = image.width;
          _this.cropImgHeight = image.height;
        };
      };

      //转化为base64
      reader.readAsDataURL(file);
    },
    downloadQrImg() {
      const downloadElement = document.createElement("a");
      downloadElement.href = this.QRImgUrl;
      downloadElement.download = "二维码.png";
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
    },
    //裁剪并直接下载
    download() {
      const _this = this;
      this.$refs.cropper.getCropBlob(function (blob) {
        const href = window.URL.createObjectURL(blob);
        const downloadElement = document.createElement("a");
        downloadElement.href = href;
        downloadElement.download = _this.imgName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }, "image/jpeg");
    },
    copyImgBase64(content) {
      navigator.clipboard.writeText(content).then(() => {
        this.successMsgBox("复制成功");
      });
    },
    base64toImg(base64String) {
      // Base64编码的字符串

      // 创建Image对象并设置src属性为Base64字符串
      var image = new Image();
      image.src = base64String;

      // 等待图像加载完成后显示到页面上

      const downloadElement = document.createElement("a");
      downloadElement.href = base64String;
      downloadElement.download = "test.png";
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
    },
    clearImgBase64() {
      this.imgBase64 = "";
      this.$refs.baseUpload.clearFiles();
    },
    getfilesize(size) {
      if (!size) return "";
      var num = 1024.0; //byte

      if (size < num) return size + "B";
      if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + "K"; //kb
      if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
      if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
      return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
    },

    //使用canvas
    handleAvatarSuccess(f) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const img = new Image();
      img.src = f.url;
      let _this = this;
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);
        const base64 = canvas.toDataURL();
        _this.imgBase64 = base64;

        // var str = base64.replace("data:image/png;base64,", "");
        // var equalIndex = str.indexOf("=");
        // if (str.indexOf("=") > 0) {
        //   str = str.substring(0, equalIndex);
        // }
        // var strLength = str.length;

        // // alert(_this.getfilesize(parseInt(strLength - (strLength / 8) * 2)));

        // let file_size = (len(base64) * 6 - base64.count("=") * 8) / 8;

        // alert(_this.getfilesize(file_size));
      };
    },

    //上传图片
    uploadImg(type) {
      let _this = this;
      if (type === "blob") {
        //获取截图的blob数据
        this.$refs.cropper.getCropBlob(async data => {
          let formData = new FormData();
          formData.append("file", data, "DX.jpg");
          //调用axios上传
          let {
            data: res
          } = await _this.$http.post("/api/file/imgUpload", formData);
          if (res.code === 200) {
            _this.$message({
              message: res.msg,
              type: "success"
            });
            let data = res.data.replace("[", "").replace("]", "").split(",");
            let imgInfo = {
              name: _this.Name,
              url: data[0]
            };
            _this.$emit("uploadImgSuccess", imgInfo);
          } else {
            _this.$message({
              message: "文件服务异常，请联系管理员！",
              type: "error"
            });
          }
        });
      }
    },
    gotoJsonNew() {
      window.open("https://jsonnew.com/#/");
    }
  }
};