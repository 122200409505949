export default {
  setup() {},
  props: {
    myLogo: {
      type: String
    },
    showText: {
      type: Boolean,
      default: false
    }
  }
};