import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["fill"];
const _hoisted_3 = ["fill"];
const _hoisted_4 = ["fill"];
const _hoisted_5 = ["fill"];
const _hoisted_6 = ["fill"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [(_openBlock(), _createElementBlock("svg", {
    t: "1700484950858",
    class: "icon",
    style: _normalizeStyle({
      verticalAlign: 'middle',
      display: $props.showText && $props.myLogo.isCircle == '是' ? 'block' : '',
      paddingBottom: $props.showText ? $props.myLogo.iconPaddingBottom : '',
      paddingTop: $props.showText ? $props.myLogo.iconPaddingTop : ''
    }),
    viewBox: "0 0 1024 1024",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    "p-id": "2744",
    width: $props.showText ? $props.myLogo.iconSize : '32',
    height: $props.showText ? $props.myLogo.iconSize : '32'
  }, [_createElementVNode("path", {
    d: "M592 622.016c0 54.08-35.84 97.984-80 97.984s-80-43.872-80-98.016S467.84 496 512 496s80 71.872 80 126.016z",
    fill: $props.myLogo.color1,
    "p-id": "2745"
  }, null, 8, _hoisted_2), _createElementVNode("path", {
    d: "M320 544m-32 0a32 32 0 1 0 64 0 32 32 0 1 0-64 0Z",
    fill: $props.myLogo.color1,
    "p-id": "2746"
  }, null, 8, _hoisted_3), _createElementVNode("path", {
    d: "M704 544m-32 0a32 32 0 1 0 64 0 32 32 0 1 0-64 0Z",
    fill: $props.myLogo.color1,
    "p-id": "2747"
  }, null, 8, _hoisted_4), _createElementVNode("path", {
    d: "M881.536 162.56c-51.072-9.664-104.32 7.68-142.464 46.176a185.216 185.216 0 0 0-32.352 45.312A348.576 348.576 0 0 0 508 192c-71.232 0-137.472 21.536-192.96 58.24a184.576 184.576 0 0 0-30.08-41.472C246.784 170.24 193.6 153.024 142.432 162.56 92 172 47.296 207.168 22.816 256.576c-24.928 50.336-24.96 106.208-20.64 144.192 5.28 46.304 18.88 89.408 32 131.104 4.704 15.072 10.56 33.824 23.36 49.728 13.696 17.024 32.8 26.4 51.648 26.4 7.072 0 14.144-1.344 20.832-4.096 12.64-5.184 21.216-14.048 27.84-22.528C174.944 749.504 314.08 864 507.968 864c197.728 0 338.24-119.2 350.752-292.8l0.192 0.256c7.296 10.624 17.28 25.184 35.04 32.448 6.688 2.752 13.76 4.096 20.832 4.096 18.88 0 37.952-9.376 51.68-26.4 12.736-15.904 18.624-34.656 23.36-49.696 13.12-41.728 26.688-84.832 31.968-131.136 4.32-37.984 4.288-93.856-20.64-144.192-24.48-49.408-69.184-84.576-119.68-94.016zM118.144 526.976c-1.984 2.656-3.904 5.44-5.824 8.224l-4.608 6.688-0.32-0.384c-5.184-6.464-8.896-18.304-12.16-28.8-12.224-38.848-24.864-79.008-29.44-119.2-3.392-29.44-3.616-72.192 14.4-108.512 15.36-31.04 43.744-53.856 74.08-59.52 29.76-5.568 62.368 5.28 85.184 28.32 9.28 9.376 17.056 20.48 23.104 33.088 0.64 1.376 1.792 2.24 2.592 3.456a353.504 353.504 0 0 0-105.856 209.664c-14.944 2.592-29.76 11.744-41.152 26.976zM508 800c-169.568 0-288-104.512-288-254.112 0-159.872 129.216-289.888 288-289.888s288 130.016 288 289.888c0 149.6-118.432 254.112-288 254.112z m450.24-406.496c-4.608 40.192-17.248 80.352-29.44 119.2-3.296 10.496-7.04 22.336-12.16 28.8l-0.32 0.288a211.68 211.68 0 0 1-4.64-6.592 245.12 245.12 0 0 0-5.824-8.192c-13.44-17.952-31.648-27.552-49.28-27.712a353.664 353.664 0 0 0-100.992-204.64 31.2 31.2 0 0 0 5.856-7.776c6.08-12.608 13.824-23.712 23.136-33.12 22.784-23.04 55.392-33.856 85.12-28.32 30.368 5.696 58.752 28.512 74.144 59.52 17.984 36.352 17.76 79.104 14.4 108.544z",
    fill: $props.myLogo.color1,
    "p-id": "2748"
  }, null, 8, _hoisted_5), _createElementVNode("path", {
    d: "M512 480c-57.728 0-96 85.44-96 142.016C416 684.832 459.072 736 512 736s96-51.136 96-114.016C608 565.504 569.728 480 512 480z m0 224c-35.296 0-64-36.8-64-82.016 0-47.008 31.648-109.984 64-109.984s64 62.976 64 110.016C576 667.2 547.296 704 512 704z",
    fill: $props.myLogo.color1,
    "p-id": "2749"
  }, null, 8, _hoisted_6)], 12, _hoisted_1)), $props.showText ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    style: _normalizeStyle([{
      fontFamily: $props.myLogo.fontFamily,
      color: $props.myLogo.color1,
      fontSize: `${$props.myLogo.fontSize}px`
    }, {
      "font-weight": "bold"
    }])
  }, _toDisplayString($props.myLogo.inputContent), 5)) : _createCommentVNode("", true)]);
}