import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index.js";
const app = createApp(App);

import lodash from "lodash";
window._ = lodash;

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
app.use(ElementPlus);

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

import qs from "qs";
app.config.globalProperties.$qs = qs;
import axios from "axios";
app.config.globalProperties.$axios = axios;
//axios.defaults.baseURL = "http://localhost:8208";
axios.defaults.baseURL = "https://itlifetime.com/freeweb";
axios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    //判断是否存在token，如果存在将每个页面header都添加token
    if (sessionStorage.getItem("freeAccessToken")) {
      // config.headers.common["Authorization"] =
      //   sessionStorage.getItem("accessToken");
      config.headers["Authorization"] =
        sessionStorage.getItem("freeAccessToken");
    }
    // 拦截失效、非法请求
    // else{
    //   config.cancelToken = new cancelToken((c) => {
    //     this.errorMsgBox("登录失效，请重新登录")
    //   });
    // }

    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    alert(error);

    if (error.response) {
      switch (error.response.status) {
        case 401:
          this.$store.commit("del_token");
          router.replace({
            path: "/login",
            query: {
              redirect: router.currentRoute.fullPath,
            }, //登录成功后跳入浏览的当前页面
          });
          break;
        case 500:
          Message;
      }
      return Promise.reject(error.response.data);
    } else {
      //TODO:网络超时反应，返回指定提示内容，使用时，需要根据
      return window.const.net_connected_error;
    }
  }
);
//组件复用
app.mixin({
  setup() {},
  methods: {
    errorMsgBox(notice) {
      this.$message({
        message: notice,
        type: "error",
      });
    },
    successMsgBox(notice) {
      if (!notice) {
        notice = "成功";
      }
      this.$message({
        message: notice,
        type: "info",
      });
    },
    vantDialog(msg) {
      showDialog({ type: "danger", message: msg });
    },
    vantNotify(msg) {
      showNotify({ position: "bottom", type: "danger", message: msg });
    },
    vantSuccessNotify(msg) {
      showNotify({ position: "bottom", type: "success", message: msg });
    },

    //form表单提交，参数不用转换，自动修改头
    formDataApi(data, api) {
      return async () => {
        let res = null;
        try {
          res = await axios.post(api, data);
          res = res.data;
          return res;
        } catch (e) {
          this.$message({
            message: e.message,
            type: "error",
          });
          if (_.isFunction(error)) {
            await error(e);
          }
          return defaultValue;
        }
      };
    },
    apiAll(data, api, config = {}) {
      //1、默认api
      if (!api) {
        api = window.http;
      }
      //2、上传地址时使用心得content-type

      if (api == "/newHuiyiUpload") {
        //文件上传头Content-Type
        axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
      } else {
        //默认头Content-Type
        axios.defaults.headers.post["Content-Type"] =
          "application/x-www-form-urlencoded";
      }
      //3、默认空参数
      if (!data) {
        const params = {};
        data.params = JSON.stringify(params);
      }
      let qsParam = qs.stringify(data);
      return async () => {
        const { method = "POST", error, defaultValue = null } = config;
        let res = null;
        try {
          if (method.toUpperCase() === "GET") {
            res = await axios.get(api, {
              params: qsParam,
            });
          } else {
            res = await axios.post(api, qsParam);
          }
          res = res.data;
          return res;
        } catch (e) {
          this.$message({
            message: e.message,
            type: "error",
          });
          if (_.isFunction(error)) {
            await error(e);
          }
          return defaultValue;
        }
      };
    },
    async withLoading(asyncFunc) {
      let res = null;
      try {
        res = await asyncFunc();
      } catch (e) {
        console.error("WithLoading Error: ", e);
      }
      return res;
    },
  },
});
app.use(router).mount("#app");
